
.menu {
    .card {
        width: 100%;
        padding-top: var(--space-md);
        .back {
            font-size: var(--text-md);
            color: var(--color-contrast-high)
        }
    }
}

.overview {
    p.description {
        font-size: var(--text-lg);
        margin-bottom: var(--space-sm);
        padding-left: 0.6rem;
        border-left: 0.25rem solid var(--color-contrast-medium);
    }

    p.date {
        font-size: var(--text-md);
        color: var(contrast-high);
        margin: 0;
    }
}

.players {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    gap: var(--space-xs);
    flex-wrap: wrap;
    font-size: var(--text-base);
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);

    .player {
        flex-grow: 1;
        background-color: var(--color-bg-dark);
        border-radius: var(--space-sm);
        overflow: hidden;
        padding: 0;
        min-width: 20em;

        .player_header {
            padding: var(--space-sm);
            padding-bottom: var(--space-xs);
            border-bottom: var(--space-2xs) solid;
        }
    }
}

.header {
    width: 100%;
    text-shadow: 0.2rem 0.2rem 0.7rem rgba(0, 0, 0, 0.75);
}

figure.roll_dist {
    margin-bottom: var(--space-sm);

    svg {
        border-bottom: var(--space-3xs) var(--color-bg-dark) solid;
    }
}
