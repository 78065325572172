
.back {
    font-size: var(--text-md);
    margin-bottom: var(--space-sm);
}

.form {
    padding-top: 0;

    .desc {
        margin: var(--space-sm) 0 var(--space-md);
    }

    form input {
        font-size: var(--text-md);
    }

    .checkbox {
        margin-bottom: var(--space-md);
    }

    .display_name_input_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        font-size: var(--text-md);
        margin: var(--space-3xs) 0 var(--space-2xs);

        input {
            flex-grow: 1;
            margin: 0;
        }

        button.randomise_display_name {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            width: 2.6em;
            align-self: stretch;
            margin: 0 0 0 var(--space-2xs);
            padding: 0;

            color: var(--color-contrast-between_lower_low);
            background-color: var(--color-contrast-medium);
            border-bottom: var(--space-3xs) var(--color-contrast-between_lower_low) solid;
            background-image: none;

            &:hover {
                color: var(--color-contrast-low);
                background-color: var(--color-contrast-above);
            }
            &:active {
                background-color: var(--color-contrast-medium);
            }

            svg {
                fill: white;
            }
        }
    }
}
