
.container {
    display: flex;
    flex-direction: column;
}

.subtitle_line {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: var(--space-2xs);
    gap: var(--space-xs);

    .subtitle {
        flex-grow: 1;
        p {
            color: var(--color-contrast-high);
            font-weight: bold;
            margin: 0;
            width: 100%;

            &.title_like_desc {
                color: var(--color-contrast-higher);
            }
        }
    }

    button {
        padding: var(--space-3xs) var(--space-2xs);
        color: var(--color-contrast-high);
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: var(--space-2xs);
        font-size: var(--text-base);
        cursor: pointer;

        &:hover {
            color: var(--color-contrast-higher);
        }
        &:active {
            color: var(--color-contrast-high);
        }
    }
}
