@use "@/scss/config" as *;

.menu_container {
    display: block;
    width: 100%;
}

.menu_container.portrait,
.selected_options {
    max-width: 42em;
}
.selected_option {
    margin-bottom: var(--space-sm);
}

.menu {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--space-sm);

    @include min-width(sm) {
        grid-template-columns: 1fr 1fr;
    }
}

.selected_desc {
    font-size: var(--text-base);
}
