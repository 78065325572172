@use "@/scss/config" as *;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.intro, .settings, .info {
  display: block;
  padding: 0 var(--space-xs);
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width-sm);
  width: 100%;

  section {
    margin-bottom: var(--space-md);
  }

  .quick_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--space-sm);
    row-gap: var(--space-sm);
  }
}

.settings_container {
  padding: var(--space-md) 0;
  padding-bottom: var(--space-lg);
}

.collision_image {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-height: 10em;
}

.banner_ad_small {
  display: flex;
  flex-direction: column;
  font-size: var(--text-base);
  color: var(--color-contrast-high);
  pointer-events: auto;
  max-height: 8em;
  max-width: 28em;

  span {
    display: block;
    padding-bottom: 0.2em;
    flex-grow: 0;
  }

  img {
    flex-grow: 1;
    object-fit: contain;
    object-position: top left;
  }
}

.signin {
  background-color: var(--color-bg);
  border-bottom: 0.4rem solid var(--color-bg-dark);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: var(--space-sm);

  padding: var(--space-sm);
  margin-bottom: var(--space-sm);
  overflow: hidden;

  @include min-width(sm) {
    padding: var(--space-md);
  }

  .signin_container {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);

    .prompt, .buttons {
      display: flex;
      flex-direction: column;
      gap: var(--space-xs);

      @include min-width(sm) {
        flex-direction: row;
      }
    }
    .prompt {
      flex: 0.6;
    }
    .buttons {
      flex: 0.4;
    }
  }
}

.section {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: var(--space-sm);
  width: 100%;
  overflow: hidden;

  padding: var(--space-xs);
  @include min-width(sm) {
    padding: var(--space-sm);
  }

  p {
    margin: 0;
  }
}
