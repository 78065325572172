
.setup_panel {
    h3 {
        margin-top: var(--space-sm);
    }

    .back_link {
        color: var(--color-contrast-high);
        font-size: var(--text-md);
        margin-bottom: var(--space-md);
    }

    .cannot_play_message {
        margin-top: var(--space-sm);
        border: 1px solid var(--color-primary-darker);
        border-radius: var(--space-sm);
        background-color: var(--color-bg);
        padding: var(--space-sm);
        width: 100%;
    }

    .board_icon {
        display: inline-block;
        width: 1.8em;
        vertical-align: middle;
        margin-right: 0.2em;
    }
}
