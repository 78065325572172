@use "@/scss/config" as *;

.error {
    width: 100%;
    color: var(--color-error);
    text-align: left;
}

.container {
    display: flex;
    width: 100%;

    &.in_article {
        min-height: 10rem;

        @include min-width(sm) {
            min-height: 18rem;
        }
    }
}
