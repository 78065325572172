@use "@/scss/config" as *;

.articles {
    display: flex;
    flex-direction: column;
    gap: 2em;

    .article_link {
        display: flex;
        border-radius: 1em;
        overflow: hidden;
        background-color: var(--color-contrast-low);
        border-bottom: var(--space-3xs) var(--color-contrast-lower) solid;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

        flex-direction: column;
        &.not_compact {
            @include min-width(sm) {
                flex-direction: row;
            }
        }

        .article_image_link img {
            object-fit: cover;
            height: 100%;
            max-height: 10em;
        }
        &.not_compact .article_image_link img {
            @include min-width(sm) {
                max-height: none;
                max-width: 16em;
            }
        }

        .article_link_content {
            padding: 1em;

            .article_date {
                color: var(--color-contrast-medium);
                margin-bottom: var(--space-xs);
            }

            & > a {
                color: var(--color-contrast-high);
            }

            h3 {
                color: var(--color-contrast-higher);
                margin-top: 0;
            }
            p {
                color: var(--color-contrast-high);
                margin: 0;
            }
        }
    }
}
