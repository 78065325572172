
.container {
    display: flex;
    position: relative;
    padding: 0;

    .check {
        color: white;
        fill: currentColor;
        display: block;
        position: absolute;
        width: 80%;
        height: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }
}
