
select.dropdown {
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: var(--space-4xs) var(--space-sm);
    font-size: var(--text-base);
    color: #333;

    &:hover {
        border-color: #888;
    }

    &:focus {
        outline: none;
        border-color: #0052cc;
        box-shadow: 0 0 0 3px rgba(0, 82, 204, 0.25);
    }

    &:disabled {
        background-color: #e7e7e7;
        color: #a1a1a1;
        border-color: #d3d3d3;
        &:hover {
            border-color: #d3d3d3;
        }
    }

    option {
        padding: 10px;
    }
}
