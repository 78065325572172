
.menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--color-bg-darker);
    font-size: var(--text-lg);

    button {
        cursor: pointer;
        width: 100%;
        color: var(--color-contrast-higher);
        font-size: var(--text-md);
        font-weight: bold;
        padding: var(--space-sm) var(--space-md);
        padding-left: var(--space-sm);
        background-color: var(--color-bg);
        text-align: left;

        &:hover {
            color: var(--color-contrast-higher);
            background-color: var(--color-bg-darkest);
            text-decoration: none;
        }

        img, svg {
            display: inline-block;
            width: 100%;
            max-width: 1.5em;
            max-height: 1.5em;
            margin-right: var(--space-2xs);
            vertical-align: middle;
            fill: currentColor;
        }

        span {
            vertical-align: middle;
        }

        &.copied {
            color: var(--color-success-lighter);
        }
    }
}

.menu {
    &#{&} {
        padding: 0;
        border: 1px solid var(color-bg);
        border-radius: var(--space-sm);
        background-color: var(--color-bg-darker);
        overflow: hidden;
        min-width: 260px;
    }
}

.button {
    color: var(--color-contrast-high);
    font-size: var(--text-md);

    &:hover {
        color: var(--color-contrast-higher);
    }
}

.share_icon {
    display: inline;
    height: 0.8em;
    margin-right: 0.4em;
    fill: currentColor;
}
