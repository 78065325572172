
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-radius: var(--space-sm);
    background-color: rgba(0, 0, 0, 0.3);

    color: var(--color-contrast-high);
    font-size: var(--text-base);

    &.container_with_top_padding {
        padding-top: var(--space-2xs);
    }

    .entry {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--space-xs);
        padding: var(--space-2xs) var(--space-sm);

        .position {
            font-weight: bold;
            min-width: 1em;
        }
        .display_name {
            font-weight: bold;
        }
        .value {
            margin-left: auto;
        }
    }

    .your_entry {
        background-color: rgba(0, 0, 0, 0.3);
        padding: var(--space-sm);
    }
}
