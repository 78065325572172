
div.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: var(--space-sm) 0;

    label {
        font-size: var(--text-base);
        font-weight: normal;
        margin: 0 0 0 var(--space-xs);
        cursor: pointer;

        &.disabled {
            color: var(--color-contrast-medium);
            cursor: auto;
        }
    }
}
