
div.container {
    margin: var(--space-sm) 0;

    &.no_margins {
        margin: 0;
    }

    label {
        font-size: var(--text-base);
        margin: 0 var(--space-xs) var(--space-2xs) 0;
        cursor: pointer;

        &.disabled {
            color: var(--color-contrast-medium);
            cursor: auto;
        }
    }

    select {
        width: auto;
        min-width: 8em;
    }
}
