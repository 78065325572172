
.container {
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    padding: 0.5em 0.5em 1.4em;
    width: 18em;
    min-height: 10em;
    margin-bottom: 2em;

    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 1em;
    overflow: hidden;

    font-size: var(--text-base);

    .prompt {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0.3em 0.9em;
        color: var(--color-contrast-higher);
        font-size: var(--text-base);
        font-weight: normal;
        background-color: var(--color-bg);
        border-top-right-radius: 1em;
        pointer-events: none;
    }
}
