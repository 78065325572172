@use "@/scss/config" as *;

.section {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: var(--space-sm);
    width: 100%;
    overflow: hidden;

    padding: var(--space-xs);
    @include min-width(sm) {
        padding: var(--space-sm);
    }

    p {
        margin: 0;
    }
}
