
.dir_container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: var(--space-xs);
    padding-left: var(--space-xs);
    padding-right: var(--space-xs);
}
