@use "@/scss/config" as *;

.author_and_date {
    color: var(--color-contrast-medium);
    margin-top: var(--space-md);
    margin-bottom: var(--space-lg);
    font-size: var(--text-base);

    @include min-width(sm) {
        margin-top: var(--space-sm);
    }

    /* We need the extra specificity... */
    &#{&} {
        &#{&} {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .author {
        color: var(--color-primary);
        text-decoration: none;

        &:hover {
            color: var(--color-primary-light);
            text-decoration: underline;
        }
    }

    .date {
        color: var(--color-contrast-high);
    }
}
